import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProjectService } from 'src/app/shared/project.service';
import { PropertyService } from 'src/app/shared/property.service';
import { PropertyDetailsDialogComponent } from '../property-details-dialog/property-details-dialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  lots: any = [];
  pagedLots: any = [];
  pageSize = 8;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  proyectos: any[] = [];
  selectedProyecto: string = '';
  codigo: string = '';
  estado: string = 'TODOS';
  private inputTimeout: any;

  constructor(private propertyService: PropertyService, private projectService: ProjectService, public dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    await this.listProjects();
    await this.listProperties();
  }

  async listProjects(): Promise<void> {
    this.blockUI.start('Cargando lista de proyectos...');
    try {
      const response = await this.projectService.getProjects();
      if (response && Array.isArray(response.data)) {
        this.proyectos = response.data;
        this.selectedProyecto = this.proyectos[0].id;
      }
    } catch (error) {
      console.error('Error al obtener proyectos:', error);
      throw error;
    }
    this.blockUI.stop();
  }

  async listProperties(): Promise<void> {
    this.blockUI.start('Cargando lista de lotes...');
    try {
      const codeProp = (this.codigo !== '') ? this.codigo : 'TODOS';
      const statusProp = (this.estado !== 'TODOS') ? this.estado : 'TODOS';
      const response = await this.propertyService.getPropertiesByCodeAndStatusAndProject(codeProp, statusProp, this.selectedProyecto);
      if (response && Array.isArray(response.data)) {
        this.lots = response.data;
        this.setPage(0);
      }
    } catch (error) {
      console.error('Error al obtener propiedades:', error);
      throw error;
    }
    this.blockUI.stop();
  }

  onPageChange(event: any) {
    this.setPage(event.pageIndex);
  }

  setPage(pageIndex: number): void {
    const startIndex = pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedLots = this.lots.slice(startIndex, endIndex);
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'DISPONIBLE':
        return 'available';
      case 'PRE-RESERVA':
        return 'pre-reserved';
      case 'RESERVADO':
        return 'reserved';
      case 'VENDIDO':
        return 'sold';
      default:
        return '';
    }
  }
  
  onInputChange() {
    clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.listProperties();
    }, 2000);
  }
  
  onStateChange() {
    this.listProperties();
  }

  viewDetails(property: any) {
    const dialogRef = this.dialog.open(PropertyDetailsDialogComponent, {
      data: property,
      width: '700px',
      height: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El diálogo fue cerrado');
    });
  }

  async changeLotStatus(lot: any): Promise<void> {
    console.log('Cambiando estado del lote:', lot);
    if (lot.status === 'DISPONIBLE' || lot.status === 'RESERVADO' || lot.status === 'VENDIDO') {
      const result = await Swal.fire({
        title: 'Cambiar estado',
        input: 'select',
        inputOptions: {
          DISPONIBLE: 'DISPONIBLE',
          VENDIDO: 'VENDIDO'
        },
        inputPlaceholder: 'Selecciona un estado',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Debes seleccionar un estado';
          }
        }
      });

      if (result.isConfirmed) {
        lot.status = result.value;
  
        try {
          await this.updateStatusProperty(lot.id, result.value);
          await Swal.fire('Estado actualizado', `El estado ahora es ${lot.status}`, 'success');
        } catch (error) {
          console.error('Error al actualizar el estado del lote:', error);
          Swal.fire('Error', 'No se pudo actualizar el estado. Intenta nuevamente.', 'error');
        }
      }
    }
  }

  async updateStatusProperty(id: string, status: string): Promise<void> {
    this.blockUI.start('Actualizando estado del lote...');
    try {
      await this.propertyService.updateStatus(id, status);
    } catch (error) {
      throw error;
    }
    this.blockUI.stop();
  }
}
