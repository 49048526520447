import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent {
  @Input() usuario: { nombre: string };
  @Output() toggleMenu = new EventEmitter<void>();

  constructor(private router: Router) {}

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userName');

    this.router.navigate(['/login']);
  }
}
