import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/pages/login/login.component';
import { ReserveComponent } from './views/pages/reserve/reserve.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ImageModalComponent } from './views/pages/image-modal/image-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { ToolbarComponent } from './core/toolbar/toolbar.component';
import { LayoutComponent } from './views/layout/layout.component';
import { PropertyComponent } from './views/pages/property/property.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { BlockUIModule } from 'ng-block-ui';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { PropertyDetailsDialogComponent } from './views/pages/property-details-dialog/property-details-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { UpdateProjectPlanComponent } from './views/pages/update-project-plan/update-project-plan.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ReserveComponent,
    ImageModalComponent,
    ToolbarComponent,
    LayoutComponent,
    PropertyComponent,
    PropertyDetailsDialogComponent,
    UpdateProjectPlanComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    BlockUIModule.forRoot(),
    MatPaginatorModule,
    MatTabsModule,
    MatMenuModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [PropertyDetailsDialogComponent]
})
export class AppModule { }
