import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/pages/login/login.component';
import { ReserveComponent } from './views/pages/reserve/reserve.component';
import { AuthGuard } from './auth.guard';
import { LayoutComponent } from './views/layout/layout.component';
import { PropertyComponent } from './views/pages/property/property.component';
import { LoginGuard } from './login.guard';
import { UpdateProjectPlanComponent } from './views/pages/update-project-plan/update-project-plan.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full', canActivate: [LoginGuard] },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'reserve', component: ReserveComponent },
      { path: 'property', component: PropertyComponent },
      { path: 'update-project-plan', component: UpdateProjectPlanComponent },
    ]
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
